export class TagsMapping {
  public static TAGS = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "tags[].id",
    "items[].name": "tags[].name",
    "items[].color": "tags[].color",
    "items[].isPrivate": "tags[].isPrivate",
    "items[].isEditableForLoggedUser": "tags[].isEditableForLoggedUser",
  };
}
