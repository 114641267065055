export class RulesMapping {
  public static ALL_RULES = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "rules[].id",
    "items[].name": "rules[].name",
  };
  public static SINGLE_RULE = {
    id: "id",
    name: "name",
    sender: "sender",
    senderValue: "senderValue",
    senderLogicalOperator: "senderLogicalOperator",
    receiver: "receiver",
    receiverValue: "receiverValue",
    receiverLogicalOperator: "receiverLogicalOperator",
    subject: "subject",
    subjectValue: "subjectValue",
    "actions[].type": "actions[].type",
    "actions[].value": "actions[].value",
  };
}
