import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { EmailResponses, ResponseParams } from "models/response.model";
import { Email } from "interfaces/email.interface";

export function responseService() {}
const _path = `${Config.apis.response}`;

responseService.getResponseOnMail = async (body: ResponseParams, mapping?: any): Promise<EmailResponses> => {
  return await API.get(`${_path}`, { params: body })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

responseService.getSingleResponse = async (id: number, mapping?: any): Promise<Email> => {
  return await API.get(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
