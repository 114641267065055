import axios from "axios";
import Config from "Config";
import { getFromLocalStorage, setOnLocalStorage } from "libs/helpers/localstorage";
import Qs from "qs";
import { PathPage } from "./path-page";

let API = axios.create({
  baseURL: `${Config.apiBaseurl}/api`,
  responseType: "json",
});

// REFRESH TOKEN LOGIC
let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/**
 * Interceptor all request
 */
API.interceptors.request.use(
  (config) => {
    let token: any = getFromLocalStorage("token");
    if (token) config.headers.Authorization = `Bearer ${token.data}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Interceptor all response
 */
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return API(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        let refresh_token: any = getFromLocalStorage("refresh_token");
        API.post(
          `${Config.apis.auth}/login`,
          Qs.stringify({
            refresh_token: refresh_token.data,
            grant_type: "refresh_token",
          }),
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
          .then(({ data }) => {
            if (data.access_token) setOnLocalStorage(data.access_token, "token");
            if (data.refresh_token) setOnLocalStorage(data.refresh_token, "refresh_token");
            API.defaults.headers.common["Authorization"] = "Bearer " + data.access_token;
            originalRequest.headers["Authorization"] = "Bearer " + data.access_token;
            processQueue(null, data.token);
            resolve(API(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            localStorage.clear();
            window.location.href = `/${PathPage.INBOX}`;
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export default API;
