import React, { useEffect, useState } from "react";
import { ReduxState, TeamState, TreeViewState } from "redux/types";
import { fetchTreeView } from "redux/util-repo/treeView.slice";
import { connect } from "react-redux";
import { MailOutlined, TagOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { Drawer, Tree } from "antd";
import Localize from "libs/localization";
import { PathPage } from "utils/path-page";
import { useHistory } from "react-router";
import { changeTeamDefaultExpand, changeTeamDefaultSelected } from "redux/util-repo/team.slice";

const Team = ({
  treeViewState,
  fetchTreeView,
  onClose,
  visible,
  width = "30%",
  margin,
  teamState,
  changeTeamDefaultExpand,
  changeTeamDefaultSelected,
}: {
  treeViewState: TreeViewState;
  fetchTreeView: () => void;
  onClose: () => void;
  visible: boolean;
  teamState: TeamState;
  changeTeamDefaultExpand: (teamState: TeamState) => void;
  changeTeamDefaultSelected: (teamState: TeamState) => void;
  width?: string;
  margin?: number;
}) => {
  const history = useHistory();
  useEffect(() => {
    getTreeView();
  }, []);

  const getTreeView = async () => {
    await fetchTreeView();
  };

  const renderTreeData = () => {
    return treeViewState.treeViewResponse.items.map((item) => ({
      title: item.userFullName,
      key: item.userId.toString(),
      icon: <FolderOpenOutlined />,
      children: [
        {
          title: `${item.emailsInbox.label} ${item.emailsInbox?.elementsCountLabel ? "(" + item.emailsInbox?.elementsCountLabel + ")" : ""}`,
          key: `${PathPage.INBOX}-${item.userId}`,
          switcherIcon: <MailOutlined />,
        },
        {
          title: `${item.emailsDraft.label} ${item.emailsDraft?.elementsCountLabel ? "(" + item.emailsDraft?.elementsCountLabel + ")" : ""}`,
          key: `${PathPage.DRAFTS}-${item.userId}`,
          switcherIcon: <MailOutlined />,
        },
        {
          title: `${item.emailsSent.label} ${item.emailsSent?.elementsCountLabel ? "(" + item.emailsSent?.elementsCountLabel + ")" : ""}`,
          key: `${PathPage.SENT}-${item.userId}`,
          switcherIcon: <MailOutlined />,
        },
        {
          title: `${item.emailsDeleted.label} ${item.emailsDeleted?.elementsCountLabel ? "(" + item.emailsDeleted?.elementsCountLabel + ")" : ""}`,
          key: `${PathPage.BIN}-${item.userId}`,
          switcherIcon: <MailOutlined />,
        },
        {
          title: item.emailsTagsLabel,
          key: `${PathPage.LABELS}-${item.userId}`,
          icon: item.userTags?.length === 0 && <TagOutlined />,
          switcherIcon: (item.userTags?.length === 0 || !item.userTags) && <TagOutlined />,
          children: item.userTags?.length
            ? item.userTags.map((tag) => ({
                title: tag.name + (tag.elementsCountLabel ? ` (${tag.elementsCountLabel})` : ""),
                key: `${PathPage.INBOX}-${item.userId}-${tag.id}`,
                switcherIcon: <TagOutlined />,
              }))
            : [],
        },
      ],
    }));
  };

  const onTreeElementSelect = (selectedKeys: any, info: any) => {
    changeTeamDefaultSelected({ defaultExpandValue: teamState.defaultExpandValue, isVisible: teamState.isVisible, defaultSelectedValue: selectedKeys });
    const path =
      (selectedKeys[0] as string).split("-")[0] === PathPage.LABELS
        ? (selectedKeys[0] as string).split("-")[0]
        : (selectedKeys[0] as string).split("-").length === 3
        ? `${(selectedKeys[0] as string).split("-")[0]}/${(selectedKeys[0] as string).split("-")[1]}?tagId=${(selectedKeys[0] as string).split("-")[2]}`
        : (selectedKeys[0] as string).replace("-", "/");
    history.push(`/${path}`);
  };

  const onTreeElementExpand = (selectedKeys: any, info: any) => {
    changeTeamDefaultExpand({ isVisible: teamState.isVisible, defaultExpandValue: selectedKeys, defaultSelectedValue: teamState.defaultSelectedValue });
  };

  return (
    <Drawer style={{ marginLeft: margin }} title={Localize("PAGES.TEAM.TITLE")} placement="left" closable={false} width={width} onClose={onClose} visible={visible} key="team-drawer">
      <Tree
        defaultExpandedKeys={teamState.defaultExpandValue}
        defaultSelectedKeys={teamState.defaultSelectedValue}
        showLine={true}
        showIcon={true}
        onSelect={onTreeElementSelect}
        onExpand={onTreeElementExpand}
        treeData={renderTreeData()}
      />
    </Drawer>
  );
};

const mapStateToProps = ({ treeView, team }: ReduxState) => ({
  treeViewState: treeView,
  teamState: team,
});

const mapDispatchToProps = {
  fetchTreeView,
  changeTeamDefaultExpand,
  changeTeamDefaultSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
