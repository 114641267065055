import { combineReducers } from "@reduxjs/toolkit";
import test from "redux/test-repo/test-repo";
import user from "redux/user-repo/user.slice";
import inbox from "redux/inbox-repo/inbox.slice";
import modal from "redux/util-repo/modal.slice";
import team from "redux/util-repo/team.slice";
import logs from "redux/logs-repo/logs.slice";
import tags from "redux/tags-repo/tags.slice";
import rules from "redux/rules-repo/rules.slice";
import bin from "redux/bin-repo/bin.slice";
import treeView from "redux/util-repo/treeView.slice";

const rootReducer = combineReducers({
  test,
  user,
  inbox,
  modal,
  logs,
  tags,
  rules,
  bin,
  treeView,
  team,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
