import { Component } from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Router from "./libs/router";
import ErrorBoundary from "./libs/ErrorBoundary";
import routes from "./routes";
import * as H from "history";
import { ReduxState } from "redux/types";
import { connect } from "react-redux";
import { getFromLocalStorage } from "libs/helpers/localstorage";

type props = { history: H.History; location: H.Location; authorized: boolean };
type state = {};

class App extends Component<props, state> {
  render() {
    const { authorized } = this.props;
    return (
      <BrowserRouter>
        <ErrorBoundary>
          <Router routes={routes} authorized={authorized} permissions={[]} />
        </ErrorBoundary>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ user }: ReduxState) => ({
  /* Sets the authorized flag on the routes object if an access_token is available */
  authorized: (!!user && !!user.access_token) || !!getFromLocalStorage("token"),
});

export default connect(mapStateToProps, {})(App);
