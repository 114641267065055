import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeamState } from "redux/types";

const initialState = { isVisible: false, defaultExpandValue: [], defaultSelectedValue: [] } as TeamState;

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    changeTeamVisibility: (state, action: PayloadAction<{ isVisible: boolean }>) => {
      state.isVisible = action.payload.isVisible;
      return state;
    },
    changeTeamDefaultExpand: (state, action: PayloadAction<{ defaultExpandValue: Array<string> }>) => {
      state.defaultExpandValue = action.payload.defaultExpandValue;
      return state;
    },
    changeTeamDefaultSelected: (state, action: PayloadAction<{ defaultSelectedValue: Array<string> }>) => {
      state.defaultSelectedValue = action.payload.defaultSelectedValue;
      return state;
    },
  },
});

export const { changeTeamVisibility, changeTeamDefaultExpand, changeTeamDefaultSelected } = teamSlice.actions;

export const teamSelector = (state: any) => state.team;

export default teamSlice.reducer;
