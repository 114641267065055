import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { RuleInfo, Rules, RulesSelectOptions } from "models/rules.model";
import { Rule } from "rc-field-form/lib/interface";

export function rulesService() {}
const _path = `${Config.apis.rules}`;

rulesService.getRules = async (mapping?: any): Promise<Rules> => {
  let params = new URLSearchParams([
    ["pageSize", "100"],
    ["pageNumber", "1"],
  ]);
  return await API.get(`${_path}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

rulesService.getRuleById = async (id: number, mapping?: any): Promise<RuleInfo> => {
  return await API.get(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

rulesService.postRule = async (body: any, mapping?: any): Promise<number> => {
  return await API.post(`${_path}`, body)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

rulesService.deleteRule = async (id: number, mapping?: any): Promise<any> => {
  return await API.delete(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

rulesService.getRulesActionOptions = async (mapping?: any): Promise<RulesSelectOptions> => {
  return await API.get(`${_path}/filter-rule-actions`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

rulesService.getRulesConditionOptions = async (mapping?: any): Promise<RulesSelectOptions> => {
  return await API.get(`${_path}/filter-operators`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
