import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v1 as uuid } from "uuid";

import { TestState } from "../types";

const initialState: TestState[] = [];

//Oltre a createSlice c'è anche createAction e createReducer. createSlice è una combinazione tra i 2 quindi questi 2 non servono per forza in tutti i casi.
const testSlice = createSlice({
  name: "test",
  initialState: initialState,
  reducers: {
    // Si può creare anche un obj che prende il reducer e il prepare. Il prepare serve per 'preparare' i dati che verranno poi passati al reducer.
    // Questo lo dobbiamo fare solo perché viene creato un random id. Nella creazione viene passato solo il 'desc' e nel prepare vengono aggiunti gli altri dati
    // che servono per la creazione: id e isComplete.
    create: {
      reducer: (state, action: PayloadAction<{ id: string; desc: string; isComplete: boolean }>) => {
        state.push(action.payload);
        // A differenza di redux normale, qui si può modificare direttamente lo stato grazie all'utilizzo di immer (che c'è dentro a toolkit)
      },
      prepare: ({ desc }: { desc: string }) => ({
        payload: {
          id: uuid(),
          desc,
          isComplete: false,
        },
      }),
    },
    edit: (state, action: PayloadAction<{ id: string; desc: string }>) => {
      const testToEdit = state.find((el) => el.id === action.payload.id);
      if (testToEdit) {
        testToEdit.desc = action.payload.desc;
        // A differenza di redux normale, qui si può modificare direttamente lo stato grazie all'utilizzo di immer (che c'è dentro a toolkit)
      }
    },
    remove: (state, action: PayloadAction<{ id: string }>) => {
      const index = state.findIndex((el) => el.id === action.payload.id);
      if (index !== -1) {
        state.splice(index, 1);
        // A differenza di redux normale, qui si può modificare direttamente lo stato grazie all'utilizzo di immer (che c'è dentro a toolkit)
      }
    },
  },
});

//Export action creators da usare nell'app
export const { create: createTestCreator, edit: editTestCreator, remove: deleteTestCreator } = testSlice.actions;

export default testSlice.reducer;
