export class PathPage {
  public static readonly SLASH: string = "/";

  public static readonly LOGIN: string = "login";
  public static readonly HOME: string = "home";
  public static readonly INBOX: string = "inbox";
  public static readonly RESET_PASSWORD: string = "reset-password";
  public static readonly LOGS: string = "logs";
  public static readonly LABELS: string = "labels";
  public static readonly DASHBOARD: string = "dashboard";
  public static readonly BIN: string = "bin";
  public static readonly DRAFTS: string = "drafts";
  public static readonly SENT: string = "sent";
  public static readonly LOGOUT: string = "logout/do";

  public static readonly FORBIDDEN: string = "forbidden";
  public static readonly ERROR: string = "error";
  public static readonly UNAUTHORIZED: string = "unauthorized";

  public static readonly EXAMPLE_SUB_ROUTE: string = PathPage.SLASH + PathPage.DASHBOARD + PathPage.SLASH;
}
