import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { Inbox } from "models/inbox.model";
import { Bin } from "models/bin.model";

export function binService() {}
const _path = `${Config.apis.bin}`;

binService.getEmails = async (pageNumber: number, mapping?: any, id?: number): Promise<Bin> => {
  let params = new URLSearchParams([
    ["pageSize", "10"],
    ["pageNumber", pageNumber.toString()],
  ]);
  if (id) params.append("UserId", id.toString());
  return await API.get(`${_path}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
