import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { AllUsers } from "models/users.model";
import { User } from "interfaces/user.interface";

export function usersService() {}
const _path = `${Config.apis.users}`;

usersService.getAllUsers = async (mapping?: any): Promise<AllUsers> => {
  let params = new URLSearchParams([
    // ["pageSize", "10"],
    // ["pageNumber", "1"],
    ["deleted", "false"],
  ]);
  return await API.get(`${_path}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

usersService.getUserById = async (id: number, mapping?: any): Promise<any> => {
  return await API.get(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

usersService.deleteUser = async (id: number, mapping?: any): Promise<any> => {
  return await API.delete(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

usersService.postUser = async (body: User, mapping?: any): Promise<any> => {
  return await API.post(`${_path}`, body)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response, mapping);
      }
      return response;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
