import moment from "moment";
import { upperFirst, toUpper, toLower } from "lodash";

export const getAddress = () =>
  `${global.location.protocol}//${global.location.host}/`;

// export const capitalizeFirstLetter = (string) =>
//   string.charAt(0).toUpperCase() + string.slice(1);

export const wait = (durationMs) =>
  new Promise((fulfill) => setTimeout(fulfill, durationMs));

export const refreshPage = () => {
  window.location.reload();
};

export const currencyDecimal = (value) => {
  return Number.parseFloat(value).toFixed(2).toString().concat(" €");
};

export const currencyNoDecimal = (value) => {
  return Number.parseFloat(value).toFixed(0).toString().concat("€");
};

export const isMobileDevice = () => window.screen.width < 767;

export const isTouchScreen = () =>
  !!("ontouchstart" in window || navigator.maxTouchPoints);

export const scrollToTop = () => {
  // window.scrollTo({
  //   top: 0,
  //   behavior: "smooth",
  // });
  // mobile fix
  document.body.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const scrollToId = (id) => {
  const element = document.getElementById(id);
  // if (element) element.scrollIntoView();
  if (element) {
    const top = element.offsetTop;
    window.scrollTo({ top });
  }
};

export const getAge = (birthdate) => moment().diff(moment(birthdate), "years");

export const turnKeyIntoLabel = (key) =>
  key && upperFirst(toLower(key).replace(/(\.-_)/g, " "));

export const turnIntoErrorCode = (code) =>
  code && toUpper(code.replace(/[A-Za-z0-9]+/g, " "));

export const isEmptyOrSpaces = (str) => {
  return str === null || str.match(/^ *$/) !== null || str.length === 0;
};
