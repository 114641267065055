export class LogsMapping {
  public static LOGS = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "logs[].id",
    "items[].createdAt": "logs[].date",
    "items[].emailSender": "logs[].emailSender",
    "items[].emailSubject": "logs[].emailSubject",
    "items[].emailId": "logs[].emailId",
    "items[].activity": "logs[].activity",
    "items[].createdBy.fullName": "logs[].createdBy",
    // "items[].createdBy.id": "logs[].createdBy.id",
    "items[].assignedUser.fullName": "logs[].assignedUser",
    // "items[].assignedUser.id": "logs[].assignedUser.id",
  };

  public static ACTIVITY = {
    page: "page",
    size: "size",
    total: "total",
    "items[].label": "activities[].label",
    "items[].value": "activities[].value",
  };
}
