import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { Inbox } from "models/inbox.model";
import { ActionsBody, Email } from "interfaces/email.interface";
import { SearchParams, SearchResponse } from "models/search.model";
import { Reply } from "interfaces/reply.interface";

export function inboxService() {}
const _path = `${Config.apis.inbox}`;

inboxService.getInbox = async (pageNumber: number, mapping?: any, id?: number, tagId?: number): Promise<Inbox> => {
  let params = new URLSearchParams([
    ["pageSize", "10"],
    ["pageNumber", pageNumber.toString()],
    ["deleted", "false"],
    ["withAssignedUser", "false"],
    ["isArchived", "false"],
  ]);
  if (tagId) params.append("tagId", tagId.toString());
  if (id) params.append("assignedUserId", id.toString());
  return await API.get(`${_path}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

inboxService.getEmailDetail = async (id: number, mapping?: any): Promise<Email> => {
  return await API.get(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

inboxService.emailActions = async (body: ActionsBody, mapping?: any): Promise<Inbox> => {
  return await API.put(`${_path}`, body)
    .then((response: AxiosResponse<Inbox>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

inboxService.emailSearch = async (body: SearchParams, mapping?: any): Promise<SearchResponse> => {
  return await API.get(`${_path}/search`, { params: body })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

inboxService.emailReply = async (body: Reply, id: number, attachments?: Array<any>, mapping?: any): Promise<any> => {
  let formData = new FormData();
  if (attachments && attachments.length) {
    attachments.forEach((file) => {
      formData.append("Attachments", file);
    });
  }
  Object.entries(body).forEach(([key, value]) => formData.append(key, value));
  return await API.post(`${_path}/${id}/reply`, attachments && attachments.length ? formData : body)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

inboxService.emailForward = async (body: Reply, id: number, attachments?: Array<any>, mapping?: any): Promise<any> => {
  let formData = new FormData();
  if (attachments && attachments.length) {
    attachments.forEach((file) => {
      formData.append("Attachments", file);
    });
  }
  Object.entries(body).forEach(([key, value]) => formData.append(key, value));
  return await API.post(`${_path}/${id}/forward`, attachments && attachments.length ? formData : body)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

inboxService.assignTags = async (tagsIds: Array<number>, id: number, mapping?: any): Promise<any> => {
  return await API.post(`${_path}/${id}/assign-tags`, { assignedTagsIds: tagsIds })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};
