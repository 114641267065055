import { BinMapping } from "./bin.mapping";
import { InboxMapping } from "./inbox.mapping";
import { LogsMapping } from "./logs.mapping";
import { MappingList } from "./mapping-list.enum";
import { RulesMapping } from "./rules.mapping";
import { SearchMapping } from "./search.mapping";
import { TagsMapping } from "./tags.mapping";
import { UsersMapping } from "./users.mapping";
import { UtilityMapping } from "./utility.mapping";

export class BaseMapping {
  public static response: object = {
    success: "success",
    result: "result",
  };
}

/**
 * sistema di rimapattura classi per le chiamate.
 * https://www.npmjs.com/package/object-mapper
 */
export class ClassMapping {
  public static find(key: string): object {
    let rt = null;
    if (!key) {
      throw new Error("Not found Map");
    }

    switch (key.toUpperCase()) {
      case "BASE_STATUS":
        rt = UtilityMapping.BASE_STATUS;
        break;
      case "BASE_IMAGE":
        rt = UtilityMapping.BASE_IMAGE;
        break;
      case MappingList.INBOX_MAIL:
        rt = InboxMapping.INBOX;
        break;
      case MappingList.BIN_MAIL:
        rt = BinMapping.MAIL_RESPONSE;
        break;
      case MappingList.MAIL_DETAIL:
        rt = InboxMapping.MAIL_DETAIL;
        break;
      case MappingList.ALL_USERS:
        rt = UsersMapping.ALL_USERS;
        break;
      case MappingList.SINGLE_USER:
        rt = UsersMapping.SINGLE_USER;
        break;
      case MappingList.TAGS:
        rt = TagsMapping.TAGS;
        break;
      case MappingList.ALL_RULES:
        rt = RulesMapping.ALL_RULES;
        break;
      case MappingList.SINGLE_RULE:
        rt = RulesMapping.SINGLE_RULE;
        break;
      case MappingList.SEARCH:
        rt = SearchMapping.SEARCH;
        break;
      case MappingList.TREE_VIEW:
        rt = UtilityMapping.TREE_VIEW;
        break;
      case MappingList.LOGS:
        rt = LogsMapping.LOGS;
        break;
      case MappingList.ACTIVITY:
        rt = LogsMapping.ACTIVITY;
        break;
      case MappingList.MAIL_RESPONSES:
        rt = InboxMapping.MAIL_RESPONSES;
        break;
      case MappingList.MAIL_SINGLE_RESPONSE:
        rt = InboxMapping.MAIL_SINGLE_RESPONSE;
        break;
      default:
        throw new Error("Not found Map");
    }
    return rt;
  }
}
