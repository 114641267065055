import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { Logs } from "models/logs.model";
import { Activities } from "models/activities.model";
import { LogBodyRequest } from "interfaces/log.interface";

export function activityLogService() {}
const _path = `${Config.apis.log}`;

activityLogService.getLogs = async (body: LogBodyRequest, mapping?: any): Promise<Logs> => {
  return await API.get(`${_path}`, { params: body })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

activityLogService.getActivities = async (mapping?: any): Promise<Activities> => {
  return await API.get(`${_path}/activities`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
