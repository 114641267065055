import React, { useEffect } from "react";
import { FolderOutlined, LogoutOutlined, MailOutlined, TeamOutlined, UserOutlined, UnorderedListOutlined, SettingOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "assets/images/logo_simple.png";
import { clearState } from "redux/user-repo/user.slice";

import "./Header.scss";
import { PathPage } from "utils/path-page";
import { connect } from "react-redux";
import Localize from "libs/localization";
import { fetchTreeView } from "redux/util-repo/treeView.slice";
import { ReduxState, TeamState, TreeViewState } from "redux/types";

const Header = ({
  team,
  onMenuOpen,
  onOpenTeam,
  clearUserState,
  fetchTreeView,
  treeViewState,
}: {
  team: TeamState;
  onMenuOpen: (collapse: boolean) => void;
  onOpenTeam?: (open: boolean) => void;
  clearUserState: () => void;
  fetchTreeView?: () => void;
  treeViewState: TreeViewState;
}) => {
  const [collapse, setCollapse] = React.useState(true);

  useEffect(() => {
    getTreeView();
  }, []);

  const getTreeView = async () => {
    await fetchTreeView!();
  };

  const onCollapse = () => {
    setCollapse((prev) => !prev);
    onMenuOpen(collapse);
  };
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  const history = useHistory();
  const location = useLocation();
  const logout = () => {
    localStorage.clear();
    clearUserState();
    history.push(`/${PathPage.LOGIN}`);
  };
  function navigateTo(path: string) {
    history.push(`/${path}`);
  }
  return (
    <>
      <Sider collapsible collapsed={collapse} onCollapse={onCollapse} style={{ overflow: "auto", height: "100vh", position: "fixed", top: 0, left: 0 }}>
        <div style={{ textAlign: "center", padding: 10 }}>
          <img src={Logo} style={{ maxHeight: 60 }} alt="logo" />
        </div>
        <Menu theme="dark" defaultSelectedKeys={[`/${PathPage.INBOX}`]} selectedKeys={[location.pathname]} mode="inline">
          <Menu.Item key={`/${PathPage.INBOX}`} onClick={() => navigateTo(PathPage.INBOX)} icon={<MailOutlined />}>
            Inbox
          </Menu.Item>
          <SubMenu key="sub1" icon={<FolderOutlined />} title={Localize("COMMON.OTHER_FOLDERS")}>
            <Menu.Item key={`/${PathPage.SENT}`} onClick={() => navigateTo(PathPage.SENT)}>
              {Localize("PAGES.SENT.TITLE")}
            </Menu.Item>
            <Menu.Item key={`/${PathPage.DRAFTS}`} onClick={() => navigateTo(PathPage.DRAFTS)}>
              {Localize("PAGES.DRAFTS.TITLE")}
            </Menu.Item>
            <Menu.Item key={`/${PathPage.BIN}`} onClick={() => navigateTo(PathPage.BIN)}>
              {Localize("PAGES.BIN.TITLE")}
            </Menu.Item>
            <Menu.Item key={`/${PathPage.LABELS}`} onClick={() => navigateTo(PathPage.LABELS)}>
              {Localize("PAGES.LABELS.TITLE")}
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="2" icon={<TeamOutlined />} onClick={() => onOpenTeam!(!team.isVisible)}>
            {Localize("COMMON.SHOW_ALL_TEAM")}
          </Menu.Item>
          {treeViewState.treeViewResponse.items.length && (
            <SubMenu key="team" icon={<UserSwitchOutlined />} title="Team">
              {treeViewState.treeViewResponse.items.map((item) => (
                <SubMenu key={item.userId} title={item.userFullName}>
                  <Menu.Item key={`/${PathPage.INBOX}/${item.userId}`} onClick={() => navigateTo(`${PathPage.INBOX}/${item.userId}`)}>
                    {item.emailsInbox?.label} {item.emailsInbox?.elementsCountLabel && `(${item.emailsInbox.elementsCountLabel})`}
                  </Menu.Item>
                  <Menu.Item key={`/${PathPage.DRAFTS}/${item.userId}`} onClick={() => navigateTo(`${PathPage.DRAFTS}/${item.userId}`)}>
                    {item.emailsDraft?.label} {item.emailsDraft?.elementsCountLabel && `(${item.emailsDraft.elementsCountLabel})`}
                  </Menu.Item>
                  <Menu.Item key={`/${PathPage.SENT}/${item.userId}`} onClick={() => navigateTo(`${PathPage.SENT}/${item.userId}`)}>
                    {item.emailsSent?.label} {item.emailsSent?.elementsCountLabel && `(${item.emailsSent.elementsCountLabel})`}
                  </Menu.Item>
                  <Menu.Item key={`/${PathPage.BIN}/${item.userId}`} onClick={() => navigateTo(`${PathPage.BIN}/${item.userId}`)}>
                    {item.emailsDeleted?.label} {item.emailsDeleted?.elementsCountLabel && `(${item.emailsDeleted.elementsCountLabel})`}
                  </Menu.Item>

                  {item.userTags?.length && (
                    <SubMenu key={item.emailsTagsLabel} title={item.emailsTagsLabel}>
                      {item.userTags.map((tag) => (
                        <Menu.Item key={`/${PathPage.INBOX}/${item.userId}?tagId=${tag.id}`} onClick={() => navigateTo(`${PathPage.INBOX}/${item.userId}?tagId=${tag.id}`)}>
                          {tag.name} {tag.elementsCountLabel && `(${tag.elementsCountLabel})`}
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  )}
                </SubMenu>
              ))}
            </SubMenu>
          )}
          <Menu.Item key={`/${PathPage.LOGS}`} onClick={() => navigateTo(PathPage.LOGS)} icon={<UnorderedListOutlined />}>
            {Localize("PAGES.LOGS.TITLE")}
          </Menu.Item>
          <Menu.Item key={`/${PathPage.DASHBOARD}`} onClick={() => navigateTo(PathPage.DASHBOARD)} icon={<SettingOutlined />}>
            {Localize("PAGES.DASHBOARD.TITLE")}
          </Menu.Item>
          <Menu.Item key={`/${PathPage.LOGOUT}`} onClick={logout} icon={<LogoutOutlined />}>
            {Localize("LOGIN.LOGOUT")}
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

const mapStateToProps = ({ treeView, team }: ReduxState) => ({ treeViewState: treeView, team });

const mapDispatchToProps = {
  fetchTreeView,
  clearUserState: clearState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
