import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { binService } from "api/bin.service";
import { BinEmail } from "interfaces/email.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { BinState } from "redux/types";

export const fetchBin = createAsyncThunk("bin/fetchBin", async ({ pageNumber, userId }: { pageNumber: number; userId: number }, thunkAPI) => {
  try {
    const response = await binService.getEmails(pageNumber, ClassMapping.find(MappingList.BIN_MAIL), userId);

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  binResponse: { emails: new Array<BinEmail>() },
} as BinState;

export const binSlice = createSlice({
  name: "bin",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBin.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchBin.fulfilled, (state, { payload }) => {
        state.binResponse.page = payload.page;
        state.binResponse.size = payload.size;
        state.binResponse.total = payload.total;
        state.binResponse.emails = payload.page === 1 ? payload.emails : [...state.binResponse.emails, ...payload.emails];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(fetchBin.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = binSlice.actions;

export const binSelector = (state: any) => state.bin;

export default binSlice.reducer;
