export class SearchMapping {
  public static SEARCH = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "emails[].id",
    "items[].assignedUser.fullName": "emails[].assignedUser",
    "items[].receivedDateTime": "emails[].receivedDate",
    "items[].from.emailAddress.name": "emails[].sender",
    "items[].subject": "emails[].subject",
  };
}
