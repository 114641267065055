export class UsersMapping {
  public static ALL_USERS = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "users[].id",
    "items[].fullName": "users[].fullName",
  };

  public static SINGLE_USER = {
    id: "id",
    name: "name",
    surname: "surname",
    roles: "roles",
    username: "username",
    accountOutlookId: "accountOutlookId",
  };
}
