import { configureStore, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { ThunkAction } from "redux-thunk";

import rootReducer, { RootState } from "./rootReducers";

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), logger],
  devTools: process.env.NODE_ENV !== "production",
});

// Webpack hot module replacement - update the code at runtime without requiring
// a full refresh, preserving state in the process.
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./rootReducers", () => {
    const newRootReducer = require("./rootReducers").default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
