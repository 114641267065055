export enum MappingList {
  INBOX_MAIL = "INBOX_MAIL",
  MAIL_DETAIL = "MAIL_DETAIL",
  MAIL_RESPONSES = "MAIL_RESPONSES",
  MAIL_SINGLE_RESPONSE = "MAIL_SINGLE_RESPONSE",
  ALL_USERS = "ALL_USERS",
  SINGLE_USER = "SINGLE_USER",
  TAGS = "TAGS",
  ALL_RULES = "ALL_RULES",
  SINGLE_RULE = "SINGLE_RULE",
  SEARCH = "SEARCH",
  LOGS = "LOGS",
  ACTIVITY = "ACTIVITY",
  BIN_MAIL = "BIN_MAIL",
  TREE_VIEW = "TREE_VIEW",
}
