import React, { ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "components/Header/Header";
import { Layout, Row, Col } from "antd";
import { ReduxState, TeamState, UserState } from "redux/types";
import { fetchUserBytoken } from "redux/user-repo/user.slice";

import "./app.styles.scss";
import { Footer } from "antd/lib/layout/layout";
import Team from "components/Team/Team";
import { changeTeamVisibility } from "redux/util-repo/team.slice";

const { Content } = Layout;

const AppLayout = ({
  user,
  team,
  changeTeamVisibility,
  children,
  fetchUserBytoken,
  // path,
  boxShadow = true,
  noPadding = false,
  col = { style: { height: "100%" } },
}: {
  user: UserState;
  team: TeamState;
  fetchUserBytoken: () => void;
  changeTeamVisibility: (teamState: TeamState) => void;
  children: ReactNode;
  boxShadow: boolean;
  noPadding: boolean;
  col: any;
}) => {
  const [userId] = useState(user.id);
  const [menuMargin, setMenuMargin] = useState(80);
  const teamWidth: string = "30%";
  useEffect(() => {
    if (!userId) {
      getUserInfo();
    }
  }, []);
  const getUserInfo = async () => {
    await fetchUserBytoken();
  };
  const onMenuOpen = (collapse: boolean) => setMenuMargin(collapse ? 200 : 80);
  col = { xxl: 24, xl: 24, lg: 24, md: 24, ...col };
  return (
    <Layout className="app-layout" style={{ minHeight: "100vh", marginLeft: !!user.id ? `calc(${team.isVisible ? teamWidth : "0px"} + ${menuMargin}px)` : 0 }}>
      {!!user.id && (
        <Header
          onMenuOpen={(collapse) => onMenuOpen(collapse)}
          onOpenTeam={(open) => changeTeamVisibility({ isVisible: open, defaultExpandValue: team.defaultExpandValue, defaultSelectedValue: team.defaultSelectedValue })}
        />
      )}
      {!!user.id && (
        <Team
          margin={menuMargin}
          visible={team.isVisible}
          width={teamWidth}
          onClose={() => changeTeamVisibility({ isVisible: false, defaultExpandValue: team.defaultExpandValue, defaultSelectedValue: team.defaultSelectedValue })}
        />
      )}
      <Layout className="main-layout" style={{ zIndex: 0 }}>
        <Content className={["main-cont", boxShadow ? "box-shadow soft-border" : "no-box-shadow"].join(" ")} style={{ padding: noPadding ? 0 : 24, minHeight: 280 }}>
          <Row className="main-row" justify="center">
            <Col {...col}>{children}</Col>
          </Row>
          {/* <Footer style={{ textAlign: "center" }}>© SITFA S.p.A. - P.I. IT 00472110014 - REA Torino N330031 - cap.soc. Euro 5.200.000,00 i.v.</Footer> */}
        </Content>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = ({ user, team }: ReduxState) => ({
  user,
  team,
});

const mapDispatchToProps = {
  fetchUserBytoken,
  changeTeamVisibility,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
