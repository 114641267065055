export class InboxMapping {
  public static INBOX = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "emails[].id",
    "items[].isRead": "emails[].isRead",
    "items[].hasResponsesSent": "emails[].hasResponsesSent",
    "items[].hasResponsesDraft": "emails[].hasResponsesDraft",
    "items[].subject": "emails[].subject",
    "items[].receivedDateTime": "emails[].receivedDate",
    "items[].bodyPreview": "emails[].body",
    "items[].assignedUser.id": "emails[].assignedUser.id",
    "items[].assignedUser.fullName": "emails[].assignedUser.name",
    "items[].from.emailAddress.name": "emails[].from.name",
    "items[].from.emailAddress.address": "emails[].from.address",
  };

  public static MAIL_DETAIL = {
    id: "id",
    subject: "subject",
    receivedDateTime: "receivedDate",
    "body.content": "body",
    "from.emailAddress.name": "from.name",
    "from.emailAddress.address": "from.address",
    ccRecipients: "ccRecipients",
    "attachments[].id": "attachments[].id",
    "attachments[].name": "attachments[].name",
    "attachments[].content": "attachments[].content",
    "assignedTagsIds[]": "assignedTagsIds[]",
  };

  public static MAIL_RESPONSES = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "responses[].id",
    "items[].emailId": "responses[].emailId",
    "items[].sentDateTime": "responses[].sentDate",
    "items[].subject": "responses[].subject",
    "items[].bodyPreview": "responses[].body",
    "items[].isDraft": "responses[].isDraft",
    "items[].toEmailAddresses": "responses[].toEmailAddresses",
    "items[].toCcRecipients": "responses[].toCcRecipients",
    "items[].toCcnRecipients": "responses[].toCcnRecipients",
    "items[].createdBy.fullName": "responses[].createdUserFullname",
    "items[].createdBy.id": "responses[].createdUserId",
    "items[].hasAttachments": "responses[].hasAttachments",
  };

  public static MAIL_SINGLE_RESPONSE = {
    id: "id",
    sentDateTime: "sentDate",
    subject: "subject",
    bodyContent: "body",
    isDraft: "isDraft",
    toEmailAddresses: "toEmailAddresses",
    ccRecipients: "toCcRecipients",
    ccnRecipients: "toCcnRecipients",
    bodyContentType: "bodyContentType",
    attachments: "attachments",
  };
}
