import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";
import { Tags } from "models/tags.model";
import { Tag } from "interfaces/tag.interface";

export function tagsService() {}
const _path = `${Config.apis.tags}`;

tagsService.getTags = async (mapping?: any): Promise<Tags> => {
  let params = new URLSearchParams([["deleted", "false"]]);
  return await API.get(`${_path}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

tagsService.getTagById = async (id: number, mapping?: any): Promise<any> => {
  return await API.get(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

tagsService.getEmailTags = async (emailId: number, mapping?: any): Promise<any> => {
  return await API.get(`${_path}/email/${emailId}/assignable`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

tagsService.postTag = async (body: Tag, mapping?: any): Promise<number> => {
  return await API.post(`${_path}`, body)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};

tagsService.deleteTag = async (id: number, mapping?: any): Promise<any> => {
  return await API.delete(`${_path}/${id}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response;
    })
    .catch((reason: AxiosError) => {
      return Promise.reject(reason.response?.data);
    });
};
