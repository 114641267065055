import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authService } from "api/auth.service";
import { usersService } from "api/users.service";
import { SignIn, SignInResponse } from "interfaces/auth.interface";
import { User } from "interfaces/user.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { UserInfo } from "models/users.model";
import { UserState } from "redux/types";

export const loginUser = createAsyncThunk("users/login", async ({ username, password }: SignIn, thunkAPI) => {
  try {
    const response: SignInResponse = await authService.signin({ username, password, grant_type: "password" });
    if (response.access_token) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchUserBytoken = createAsyncThunk("users/fetchUserByToken", async (undefined, thunkAPI) => {
  try {
    const response = await authService.getAccount();

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchAllUsers = createAsyncThunk("users/fetchAllUsers", async (undefined, thunkAPI) => {
  try {
    const response = await usersService.getAllUsers(ClassMapping.find(MappingList.ALL_USERS));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchUserById = createAsyncThunk("users/fetchUserById", async (id: number, thunkAPI) => {
  try {
    const response = await usersService.getUserById(id, ClassMapping.find(MappingList.SINGLE_USER));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const addUser = createAsyncThunk("users/addUser", async (userInfo: UserInfo, thunkAPI) => {
  try {
    const response = await usersService.postUser(userInfo);

    if (response.status === 200) {
      return response.status;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const deleteUser = createAsyncThunk("users/deleteUser", async (userId: number, thunkAPI) => {
  try {
    const response = await usersService.deleteUser(userId);

    if (response.status === 200) {
      return response.status;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = { username: "", email: "", isFetching: false, isSuccess: false, isError: false, errorMessage: "", access_token: "", id: 0, usersResponse: {}, singleUser: {} } as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.access_token = "";
      state.email = "";
      state.errorMessage = "";
      state.id = 0;
      state.username = "";
      state.singleUser = {} as User;

      return state;
    },
    clearSingleUser: (state) => {
      state.singleUser = {} as User;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.email = payload?.userName!;
        state.username = payload?.userName!;
        state.access_token = payload?.access_token!;
        state.isFetching = false;
        state.isSuccess = true;
        state.isError = false;
        return state;
      })
      .addCase(loginUser.rejected, (state, { payload }: any) => {
        state.isFetching = false;
        state.isError = true;
        state.errorMessage = payload.error_description;
      })
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserBytoken.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserBytoken.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.id = payload.internalId;
        state.email = payload.username;
        state.username = payload.username;
      })
      .addCase(fetchUserBytoken.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.usersResponse = payload;
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchUserById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchUserById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.singleUser = payload;
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(addUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(addUser.rejected, (state, { payload }: any) => {
        state.isFetching = false;
        state.isError = true;
        // state.errorMessage = payload.modelState.model[0];
      })
      .addCase(deleteUser.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState, clearSingleUser } = userSlice.actions;

export const userSelector = (state: any) => state.user;

export default userSlice.reducer;
