import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { tagsService } from "api/tags.service";
import { Tag } from "interfaces/tag.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { TagsState } from "redux/types";

export const fetchTags = createAsyncThunk("tags/fetchTags", async (undefined, thunkAPI) => {
  try {
    const response = await tagsService.getTags(ClassMapping.find(MappingList.TAGS));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchEmailTags = createAsyncThunk("tags/fetchEmailTags", async (id: number, thunkAPI) => {
  try {
    const response = await tagsService.getEmailTags(id, ClassMapping.find(MappingList.TAGS));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const addTag = createAsyncThunk("tags/addTag", async (tagInfo: Tag, thunkAPI) => {
  try {
    const response = await tagsService.postTag(tagInfo);

    if (response) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const removeTag = createAsyncThunk("tags/removeTag", async (tagId: number, thunkAPI) => {
  try {
    const response = await tagsService.deleteTag(tagId);

    if (response.status === 200) {
      return response.status;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = { isFetching: false, isSuccess: false, isError: false, errorMessage: "", tagsResponse: {}, tagsOnEmailResponse: {} } as TagsState;

export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchTags.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.tagsResponse = payload;
      })
      .addCase(fetchTags.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchEmailTags.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmailTags.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.tagsOnEmailResponse = payload;
      })
      .addCase(fetchEmailTags.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(addTag.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addTag.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isSuccess = true;

        if (meta.arg.id) {
          state.tagsResponse.tags[state.tagsResponse.tags.findIndex((tag) => tag.id === meta.arg.id)] = { ...meta.arg };
        } else {
          state.tagsResponse.tags = [...state.tagsResponse.tags, { ...meta.arg, id: payload }];
          state.tagsResponse.total += 1;
        }
      })
      .addCase(addTag.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(removeTag.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(removeTag.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.tagsResponse.tags = [
          ...state.tagsResponse.tags.slice(
            0,
            state.tagsResponse.tags.findIndex((tag) => tag.id === meta.arg)
          ),
          ...state.tagsResponse.tags.slice(state.tagsResponse.tags.findIndex((tag) => tag.id === meta.arg) + 1),
        ];
        state.tagsResponse.total -= 1;
      })
      .addCase(removeTag.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = tagsSlice.actions;

export const tagsSelector = (state: any) => state.tags;

export default tagsSlice.reducer;
