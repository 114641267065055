import API from "utils/API";
import Config from "Config";
import { AxiosError, AxiosResponse } from "axios";
import { Mapper } from "utils/mapper";

export function utilsService() {}

utilsService.getAccountsOutlook = async (mapping?: any): Promise<any> => {
  return await API.get(`${Config.apis.outlook}`)
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data.items, mapping);
      }
      return response.data.items;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

utilsService.getTreeView = async (mapping?: any): Promise<any> => {
  let params = new URLSearchParams([
    ["pageSize", "100"],
    ["pageNumber", "1"],
  ]);
  return await API.get(`${Config.apis.treeView}`, { params })
    .then((response: AxiosResponse<any>) => {
      if (mapping && response) {
        return Mapper.map<any>(response.data, mapping);
      }
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
