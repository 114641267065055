import AppInfo from "./libs/helpers/appInfo";

const dev = {
  env: "dev",
  label: "Development",
  logger: true,
};

const test = {
  env: "test",
  label: "Test",
  logger: true,
};

const prod = {
  env: "prod",
  label: "Staging",
  logger: false,
};

const apis = {
  auth: "/account",
  inbox: "/emails",
  users: "/users",
  tags: "/tags",
  rules: "/rules",
  log: "/email-audits",
  response: "/response-emails",
  outlook: "/account-outlooks",
  bin: "/trash",
  treeView: "/three-view"
};

const env_config = function () {
  switch (AppInfo.env) {
    case "production":
      return prod;
    case "test":
      return test;
    case "development":
      return dev;
    default:
      return dev;
  }
};

const Config = {
  // Add common config values here
  // start: new Date(),
  app_name: AppInfo.name,
  app_version: AppInfo.version,
  apiBaseurl: "https://api-test.sitfa.synesthesia.dev",
  // supported_languages: ["EN", "IT"],
  maintenance_mode: false,
  regex: {
    validString: /^[A-zÀ-ÖØ-öø-ÿ0-9\ ]{1,}$/,
    validName: /(?=^[' \-\.A-Za-zÀ-ÖØ-öø-ÿ]*$)(?=^[^'-.])(?=^[^'-])/,
    validNumber: /^[0-9]+$/,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    phone: { it: /^(00|\+)393([0-9]{2})([0-9]{6,7})$/m },
    Letters: /[a-z A-Z\-'\s]/g,
    FirstLetter: /^[a-z A-Z]/g,
    Last: /[a-zA-Z']\b/g,
    Only: /(['-/])\1/g,
    NoSubstring: /-/g,
    sdi: /[A-Za-z0-9]/,
    year: /^\d{4}$/g,
    piva: /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}[A-Z]{1}|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?([0-9]{7}[A-Z]{1}|[0-9]{1}[A-Z]{1}[0-9]{5}[A-Z]{1}|[0-9]{6}[A-Z]{2})|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NO)?[0-9]{9}MVA|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10}|(ES)?([A-Z]{1}[0-9]{8}|[0-9]{8}[A-Z]{1}|[A-Z]{1}[0-9]{7}[A-Z]{1})|(CHE)?[0-9]{9}(MWST|TVA|IVA))$/,
  },
  apis,
  ...env_config(),
  start: new Date().toISOString(),
  logger: false,
  localeCookieName: "locale",
  ...window.config,
};

export default Config;
