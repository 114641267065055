import { lazy } from "react";

import { type } from "./libs/router";
import { AppLayout } from "./layouts/index.js";
import Localize from "./libs/localization";
import { removeFromLocalStorage } from "libs/helpers/localstorage";
import { PathPage } from "utils/path-page";

const HomePage = lazy(() => import("./pages/home/home.page"));
const AccessPage = lazy(() => import("./pages/access/access.page"));
const ResetPasswordPage = lazy(() => import("./pages/reset-password/reset-password.page"));
const LogsPage = lazy(() => import("./pages/activity-log/activity-log.page"));
const LabelManagementPage = lazy(() => import("./pages/label-management/label-management.page"));
const DashboardPage = lazy(() => import("./pages/dashboard/dashboard.page"));
const BinPage = lazy(() => import("./pages/bin/bin.page"));
const DraftsPage = lazy(() => import("./pages/drafts/drafts.page"));
const SentPage = lazy(() => import("./pages/sent/sent.page"));

const routes = [
  {
    id: "home",
    title: Localize("PAGES.HOME.TITLE"),
    path: "/",
    redirectTo: `/${PathPage.INBOX}`,
    type: type.SECURED,
  },
  {
    id: "inbox",
    title: Localize("PAGES.HOME.TITLE"),
    path: `/${PathPage.INBOX}`,
    layout: AppLayout,
    component: HomePage,
    type: type.SECURED,
  },
  {
    id: "userInbox",
    title: Localize("PAGES.HOME.TITLE"),
    path: `/${PathPage.INBOX}/:userId`,
    layout: AppLayout,
    component: HomePage,
    type: type.SECURED,
  },
  {
    id: "drafts",
    title: Localize("PAGES.DRAFTS.TITLE"),
    path: `/${PathPage.DRAFTS}`,
    layout: AppLayout,
    component: DraftsPage,
    type: type.SECURED,
  },
  {
    id: "userDrafts",
    title: Localize("PAGES.DRAFTS.TITLE"),
    path: `/${PathPage.DRAFTS}/:userId`,
    layout: AppLayout,
    component: DraftsPage,
    type: type.SECURED,
  },
  {
    id: "sent",
    title: Localize("PAGES.SENT.TITLE"),
    path: `/${PathPage.SENT}`,
    layout: AppLayout,
    component: SentPage,
    type: type.SECURED,
  },
  {
    id: "userSent",
    title: Localize("PAGES.SENT.TITLE"),
    path: `/${PathPage.SENT}/:userId`,
    layout: AppLayout,
    component: SentPage,
    type: type.SECURED,
  },
  {
    id: "bin",
    title: Localize("PAGES.BIN.TITLE"),
    path: `/${PathPage.BIN}`,
    layout: AppLayout,
    component: BinPage,
    type: type.SECURED,
  },
  {
    id: "userBin",
    title: Localize("PAGES.BIN.TITLE"),
    path: `/${PathPage.BIN}/:userId`,
    layout: AppLayout,
    component: BinPage,
    type: type.SECURED,
  },
  {
    id: "login",
    title: Localize("PAGES.ACCESS.TITLE"),
    path: `/${PathPage.LOGIN}`,
    layout: AppLayout,
    component: AccessPage,
    type: type.PUBLIC,
  },
  {
    id: "Logout",
    path: `/${PathPage.LOGOUT}`,
    showInMenu: false,
    type: type.SECURED,
    component: () => {
      removeFromLocalStorage("user");
      removeFromLocalStorage("token");
      // document.location.href = `${Config.apiBaseurl}/${Config.apis.oauth.logout}?redirect_uri=${Config.appBaseurl}/login`;
      return null;
    }
  },
  {
    id: "reset-password",
    title: Localize("PAGES.RESETPASS.TITLE"),
    path: `/${PathPage.RESET_PASSWORD}`,
    layout: AppLayout,
    component: ResetPasswordPage,
    type: type.PUBLIC,
  },
  {
    id: "logs",
    title: Localize("PAGES.LOGS.TITLE"),
    path: `/${PathPage.LOGS}`,
    layout: AppLayout,
    component: LogsPage,
    type: type.SECURED,
  },
  {
    id: "labels",
    title: Localize("PAGES.LABELS.TITLE"),
    path: `/${PathPage.LABELS}`,
    layout: AppLayout,
    component: LabelManagementPage,
    type: type.SECURED,
  },
  {
    id: "dashboard",
    title: Localize("PAGES.DASHBOARD.TITLE"),
    path: `/${PathPage.DASHBOARD}`,
    layout: AppLayout,
    component: DashboardPage,
    type: type.SECURED,
  },
  {
    id: "forbidden",
    title: Localize("PAGES.FORBIDDEN.TITLE"),
    path: `/${PathPage.FORBIDDEN}`,
    layout: AppLayout,
    component: ()=><p>Forbidden</p>,
    type: type.SECURED,
  },
  {
    id: "error",
    title: Localize("PAGES.ERROR.TITLE"),
    path: `/${PathPage.ERROR}`,
    layout: AppLayout,
    component: ()=><p>Error</p>,
    type: type.SECURED,
  },
];

export default routes;
