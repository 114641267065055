import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
// import { resetErrorAction, goTo } from "../actions/app.actions.ts";
import { removeFromLocalStorage } from "./helpers/localstorage";
import { refreshPage } from "./utils";
// import ErrorPage from "../pages/system/error.page";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    // if (prevProps.error && !error) {
    //   goTo("/");
    // }
    if (!_.isEqual(error, prevProps.error)) {
      this.setState({ error });
    }
  }

  componentWillUnmount() {}

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ error });
  }

  render() {
    // const { resetErrorAction } = this.props;
    const { error } = this.state;
    if (error) {
      const { id, name, status, message, stack, date, code } = error;
      return (
        <>
          {this.props.children}
          {/* <ErrorPage
            error={{ id, name, status, message, stack, date, code }}
            // dismiss={resetErrorAction}
            forceLogin={() => {
              removeFromLocalStorage("user");
              removeFromLocalStorage("token");
              refreshPage();
            }}
          /> */}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

// const mapStateToProps = ({ app }) => ({
//   error: app.error,
// });

// export default connect(mapStateToProps, { /*resetErrorAction, goTo*/ })(
//   ErrorBoundary
// );
