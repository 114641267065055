import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { activityLogService } from "api/activityLog.service";
import { LogBodyRequest } from "interfaces/log.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { LogsState } from "redux/types";

export const fetchLogs = createAsyncThunk("logs/fetchLogs", async (body: LogBodyRequest, thunkAPI) => {
  try {
    const response = await activityLogService.getLogs(body, ClassMapping.find(MappingList.LOGS));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchActivities = createAsyncThunk("logs/fetchActivities", async (undefined, thunkAPI) => {
  try {
    const response = await activityLogService.getActivities(ClassMapping.find(MappingList.ACTIVITY));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = { isFetching: false, isSuccess: false, isError: false, errorMessage: "", logsResponse: {}, activitiesResponse: {} } as LogsState;

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogs.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchLogs.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.logsResponse = payload;
      })
      .addCase(fetchLogs.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchActivities.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchActivities.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.activitiesResponse = payload;
      })
      .addCase(fetchActivities.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = logsSlice.actions;

export const logsSelector = (state: any) => state.logs;

export default logsSlice.reducer;
