export class BinMapping {
  public static MAIL_RESPONSE = {
    page: "page",
    size: "size",
    total: "total",
    "items[].id": "emails[].id",
    "items[].isRead": "emails[].isRead",
    "items[].isDraft": "emails[].isDraft",
    "items[].hasAttachments": "emails[].hasAttachments",
    "items[].isResponseEmail": "emails[].isResponseEmail",
    "items[].receivedOrSentDateTime": "emails[].receivedDate",
    "items[].createdAt": "emails[].createdDate",
    "items[].subject": "emails[].subject",
    "items[].bodyPreview": "emails[].body",
    "items[].user.id": "emails[].createdUserId",
    "items[].user.fullName": "emails[].createdUserFullname",
    "items[].fromOrToEmailAddresses.name": "emails[].from.name",
    "items[].fromOrToEmailAddresses.address": "emails[].from.address",
    "items[].visibleTags": "emails[].assignedTagsIds",
  };
}
