export class UtilityMapping {
  public static BASE_IMAGE = {
    url: "url",
  };

  public static BASE_STATUS = {
    status: "status",
  };

  public static ACTION = {
    id: "[].id",
    description: "[].description",
  };

  public static TREE_VIEW = {
    page: "page",
    size: "size",
    total: "total",
    "items[].userId": "items[].userId",
    "items[].userFullName": "items[].userFullName",
    "items[].emailsInbox.label": "items[].emailsInbox.label",
    "items[].emailsInbox.elementsCountLabel": "items[].emailsInbox.elementsCountLabel",
    "items[].emailsSent.label": "items[].emailsSent.label",
    "items[].emailsSent.elementsCountLabel": "items[].emailsSent.elementsCountLabel",
    "items[].emailsDraft.label": "items[].emailsDraft.label",
    "items[].emailsDraft.elementsCountLabel": "items[].emailsDraft.elementsCountLabel",
    "items[].emailsDeleted.label": "items[].emailsDeleted.label",
    "items[].emailsDeleted.elementsCountLabel": "items[].emailsDeleted.elementsCountLabel",
    "items[].emailsTagsLabel": "items[].emailsTagsLabel",
    "items[].userTags[].tagId": "items[].userTags[].id",
    "items[].userTags[].isPrivate": "items[].userTags[].isPrivate",
    "items[].userTags[].label": "items[].userTags[].name",
    "items[].userTags[].elementsCountLabel": "items[].userTags[].elementsCountLabel",
  };
}
