import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { rulesService } from "api/rules.service";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { RuleInfo } from "models/rules.model";
import { RulesState } from "redux/types";

export const fetchRules = createAsyncThunk("rules/fetchRules", async (undefined, thunkAPI) => {
  try {
    const response = await rulesService.getRules(ClassMapping.find(MappingList.ALL_RULES));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchRuleById = createAsyncThunk("rules/fetchRuleById", async (id: number, thunkAPI) => {
  try {
    const response = await rulesService.getRuleById(id, ClassMapping.find(MappingList.SINGLE_RULE));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const addRule = createAsyncThunk("rules/addRule", async (ruleInfo: RuleInfo, thunkAPI) => {
  try {
    const response = await rulesService.postRule(ruleInfo);

    if (response) {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const removeRule = createAsyncThunk("rules/removeRule", async (ruleId: number, thunkAPI) => {
  try {
    const response = await rulesService.deleteRule(ruleId);

    if (response.status === 200) {
      return response.status;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchActionOptions = createAsyncThunk("rules/fetchActionOptions", async (undefined, thunkAPI) => {
  try {
    const response = await rulesService.getRulesActionOptions();

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchConditionOptions = createAsyncThunk("rules/fetchConditionOptions", async (undefined, thunkAPI) => {
  try {
    const response = await rulesService.getRulesConditionOptions();

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = { isFetching: false, isSuccess: false, isError: false, errorMessage: "", rulesResponse: {}, singleRuleResponse: {}, actionOptions: {}, conditionOptions: {} } as RulesState;

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRules.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchRules.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.rulesResponse = payload;
      })
      .addCase(fetchRules.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchRuleById.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchRuleById.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.singleRuleResponse = payload;
      })
      .addCase(fetchRuleById.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(addRule.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(addRule.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(addRule.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(removeRule.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(removeRule.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(removeRule.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchActionOptions.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchActionOptions.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.actionOptions = payload;
      })
      .addCase(fetchActionOptions.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchConditionOptions.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchConditionOptions.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.conditionOptions = payload;
      })
      .addCase(fetchConditionOptions.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = rulesSlice.actions;

export const rulesSelector = (state: any) => state.rules;

export default rulesSlice.reducer;
