import API from "utils/API";
import Qs from "qs";
import Config from "Config";
import { ResetPassword, SignIn, SignInResponse } from "interfaces/auth.interface";
import { AxiosError, AxiosResponse } from "axios";
import { setOnLocalStorage } from "libs/helpers/localstorage";

export function authService() {}
const _path = `${Config.apis.auth}`;

authService.signin = async (userData: SignIn): Promise<SignInResponse> => {
  return await API.post(`${_path}/login`, Qs.stringify(userData), {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  })
    .then((response: AxiosResponse<SignInResponse>) => {
      if (response.data.access_token) setOnLocalStorage(response.data.access_token, "token");
      if (response.data.refresh_token) setOnLocalStorage(response.data.refresh_token, "refresh_token");
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

authService.requestPassword = async (email: string): Promise<any> => {
  return await API.post(`${_path}/request-password`, { email })
    .then((response: AxiosResponse<any>) => {
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};

authService.requestResetPassword = async (email: string): Promise<AxiosResponse> => {
  return await API.post(`${_path}/request-reset`, { email })
    .then((response: AxiosResponse<any>) => {
      return response!;
    })
    .catch((reason: AxiosError) => {
      return reason.response!;
    });
};

authService.resetPassword = async (userData: ResetPassword): Promise<AxiosResponse> => {
  return await API.post(`${_path}/reset-password`, userData)
    .then((response: AxiosResponse<any>) => {
      return response!;
    })
    .catch((reason: AxiosError) => {
      return reason.response!;
    });
};

authService.getAccount = async (): Promise<any> => {
  return await API.get(`${_path}`)
    .then((response: AxiosResponse<any>) => {
      return response.data;
    })
    .catch((reason: AxiosError) => {
      return reason.response?.data;
    });
};
