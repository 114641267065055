import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalState } from "redux/types";

const initialState = { isVisible: false } as ModalState;

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    changeVisibility: (state, action: PayloadAction<{ isVisible: boolean }>) => {
      state.isVisible = action.payload.isVisible;
      return state;
    },
  },
});

export const { changeVisibility } = modalSlice.actions;

export const modalSelector = (state: any) => state.modal;

export default modalSlice.reducer;
