import React, { ComponentClass, ComponentType, FC, ReactElement, Suspense } from "react";
import { connect, ConnectedComponent } from "react-redux";
import { Switch, Route, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { pick } from "lodash";

import MetaTags from "../components/MetaTags/MetaTags";
import { removeFromLocalStorage, getFromLocalStorage } from "./helpers/localstorage";
import Loading from "../components/Loading/Loading";

export const type = {
  PUBLIC: "PUBLIC",
  SECURED: "SECURED",
  AUTHENTICATION: "AUTHENTICATION",
};

export type RouteType = {
  id: string | number;
  path: string;
  type?: string;
  icon?: ReactElement | ConnectedComponent<any, any> | ComponentClass | ComponentType | string;
  exact?: boolean;
  redirectTo?: string;
  redirectToExternal?: string;
  children?: Array<any>;
  title?: string;
  component?: any;
  noFollow?: boolean;
  layout?: any;
  layoutOptions?: object;
  scopes?: Array<string>;
  showInMenu?: boolean;
};

export const getFlatRouteList = (routes: Array<RouteType>) => {
  const routeList: Array<RouteType> = [];
  routes.map((route, i) => {
    if (route.path) {
      routeList.push(route);
    }
    if (route.children && route.children.length > 0) {
      route.children.map((child) => {
        child.parent_id = route.id;
        if (child.path) {
          routeList.push(child);
        }
      });
    }
  });
  return routeList;
};

const drawComponent = (route: RouteType, props: object) => (
  <>
    <MetaTags title={`${route.title && typeof route.title === "string" ? `${route.title}` : ""}`} noFollow={route.noFollow} />
    <Suspense fallback={<Loading coverBack={true} />}>
      {route.layout ? (
        <route.layout
          {...{
            ...props,
            ...pick(route, ["id", "parent_id", "path", "title", "showInMenu"]),
            ...route.layoutOptions,
          }}
        >
          <route.component {...props} />
        </route.layout>
      ) : (
        <route.component {...props} />
      )}
    </Suspense>
  </>
);

const Router: FC<
  {
    routes: Array<RouteType> | null;
    authorized: boolean;
    permissions: any;
  } & RouteComponentProps
> = ({ routes = null, authorized = false, permissions = {} }) =>
  routes ? (
    <>
      <Switch>
        {getFlatRouteList(routes).map((r: any, i) => (
          <Route
            key={i}
            path={r.path}
            exact={r.exact || true}
            render={(props) => {
              const { location, match } = props;
              const { pathname, hash, search } = location;
              const nextRedirectUri = `${pathname || match.url}${hash || ""}${search || ""}`;
              const ls = getFromLocalStorage();
              const redirectUri = ls && (ls as any).redirectUri;
              // console.log(r);
              // console.log(r.path, !!r.component);
              if (r.redirectToExternal) {
                window.location = r.redirectToExternal;
                return <Loading />;
              } else if (r.redirectTo) {
                return <Redirect to={r.redirectTo} />;
              } else if (!authorized && r.type === type.SECURED) {
                return <Redirect to={`/login?redirectUri=${encodeURIComponent(nextRedirectUri)}`} />;
              } else if (authorized && r.type === type.AUTHENTICATION) {
                removeFromLocalStorage("redirectUri");
                return <Redirect to={decodeURIComponent(redirectUri) || "/"} />;
              } else {
                if (!r.component) {
                  return <Loading />;
                } else {
                  if (r.type === type.SECURED && r.scopes && r.scopes.length > 0) {
                    return (r.scopes || []).some((s: string) => Object.keys(permissions).some((p) => p === s)) ? (
                      drawComponent(r, {
                        ...props,
                        permissions: (r.scopes || []).map((s: string) => ({
                          [s]: permissions[s],
                        })),
                      })
                    ) : (
                      <Redirect to={"/forbidden"} />
                    );
                  }
                  return drawComponent(r, props);
                }
              }
            }}
          />
        ))}

        <Route key={-1} render={() => <Redirect to={"/"} />} />
      </Switch>
    </>
  ) : (
    <p>No routes</p>
  );

export default withRouter(connect()(Router));
