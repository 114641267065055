import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { utilsService } from "api/utils.service";
import { TreeItem } from "interfaces/treeView.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { TreeViewState } from "redux/types";

export const fetchTreeView = createAsyncThunk("treeView/fetchTreeView", async (undefined, thunkAPI) => {
  try {
    const response = await utilsService.getTreeView(ClassMapping.find(MappingList.TREE_VIEW));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  treeViewResponse: { items: new Array<TreeItem>() },
} as TreeViewState;

export const treeViewSlice = createSlice({
  name: "treeView",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTreeView.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchTreeView.fulfilled, (state, { payload }) => {
        state.treeViewResponse.page = payload.page;
        state.treeViewResponse.size = payload.size;
        state.treeViewResponse.total = payload.total;
        state.treeViewResponse.items = payload.page === 1 ? payload.items : [...state.treeViewResponse.items, ...payload.items];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(fetchTreeView.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState } = treeViewSlice.actions;

export const treeViewSelector = (state: any) => state.treeView;

export default treeViewSlice.reducer;
