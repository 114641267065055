import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { inboxService } from "api/inbox.service";
import { responseService } from "api/response.service";
import { Email, ResponseOnEmail } from "interfaces/email.interface";
import { ClassMapping } from "libs/mapping/base.mapping";
import { MappingList } from "libs/mapping/mapping-list.enum";
import { Inbox } from "models/inbox.model";
import { ResponseParams } from "models/response.model";
import { InboxState } from "redux/types";

export const fetchInbox = createAsyncThunk("users/fetchInbox", async ({ pageNumber, filterId }: { pageNumber: number; filterId?: number }, thunkAPI) => {
  try {
    const response = await inboxService.getInbox(pageNumber, ClassMapping.find(MappingList.INBOX_MAIL), undefined, filterId);

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchOwnInbox = createAsyncThunk("users/fetchOwnInbox", async ({ id, pageNumber, filterId }: { id: number; pageNumber: number; filterId?: number }, thunkAPI) => {
  try {
    const response = await inboxService.getInbox(pageNumber, ClassMapping.find(MappingList.INBOX_MAIL), id, filterId);

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchEmailDetail = createAsyncThunk("users/fetchEmailDetail", async (id: number, thunkAPI) => {
  try {
    const response = await inboxService.getEmailDetail(id, ClassMapping.find(MappingList.MAIL_DETAIL));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchEmailResponses = createAsyncThunk("users/fetchEmailResponses", async (params: ResponseParams, thunkAPI) => {
  try {
    const response = await responseService.getResponseOnMail(params, ClassMapping.find(MappingList.MAIL_RESPONSES));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const fetchEmailSingleResponse = createAsyncThunk("users/fetchEmailSingleResponse", async (id: number, thunkAPI) => {
  try {
    const response = await responseService.getSingleResponse(id, ClassMapping.find(MappingList.MAIL_SINGLE_RESPONSE));

    if (response) {
      return { ...response };
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const assignTags = createAsyncThunk("users/assignTags", async ({ tagsIds, emailId }: { tagsIds: Array<number>; emailId: number }, thunkAPI) => {
  try {
    const response = await await inboxService.assignTags(tagsIds, emailId);

    if (response.status === 200) {
      return response.status;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e: any) {
    console.log("Error", e.response.data);
    return thunkAPI.rejectWithValue(e.response.data);
  }
});

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  allEmails: { emails: new Array<Email>() },
  ownEmails: { emails: new Array<Email>() },
  emailDetail: {},
  emailResponses: { responses: new Array<Email>() },
} as InboxState;

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
    clearEmailDetail: (state) => {
      state.emailDetail = {} as Email;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInbox.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchInbox.fulfilled, (state, { payload }) => {
        state.allEmails.page = payload.page;
        state.allEmails.size = payload.size;
        state.allEmails.total = payload.total;
        state.allEmails.emails = payload.page === 1 ? payload.emails : [...state.allEmails.emails, ...payload.emails];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(fetchInbox.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchOwnInbox.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchOwnInbox.fulfilled, (state, { payload }) => {
        state.ownEmails.page = payload.page;
        state.ownEmails.size = payload.size;
        state.ownEmails.total = payload.total;
        state.ownEmails.emails = payload.page === 1 ? payload.emails : [...state.ownEmails.emails, ...payload.emails];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(fetchOwnInbox.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchEmailDetail.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmailDetail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.emailDetail = payload;
      })
      .addCase(fetchEmailDetail.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchEmailResponses.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmailResponses.fulfilled, (state, { payload }) => {
        state.emailResponses.page = payload.page;
        state.emailResponses.size = payload.size;
        state.emailResponses.total = payload.total;
        state.emailResponses.responses = payload.page === 1 ? payload.responses : [...state.emailResponses.responses, ...payload.responses];
        state.isFetching = false;
        state.isSuccess = true;
      })
      .addCase(fetchEmailResponses.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(fetchEmailSingleResponse.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchEmailSingleResponse.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.emailDetail = payload;
      })
      .addCase(fetchEmailSingleResponse.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      })
      .addCase(assignTags.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(assignTags.fulfilled, (state, { payload, meta }) => {
        state.isFetching = false;
        state.isSuccess = true;

        state.emailDetail.assignedTagsIds = [...meta.arg.tagsIds];
      })
      .addCase(assignTags.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  },
});

export const { clearState, clearEmailDetail } = inboxSlice.actions;

export const inboxSelector = (state: any) => state.inbox;

export default inboxSlice.reducer;
